<template>
  <div class="home-hero">
    <div class="container">
      <div class="home-hero__content">
        <div class="home-hero__logo">
          <svg
            width="343"
            height="204"
            viewBox="0 0 343 204"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M122.825 4.54943L124.877 33.84H125.708C137.628 10.9344 154.453 0.31189 175.825 0.31189C202.935 0.31189 217.405 11.7647 226.774 33.84H227.578C244.431 7.09768 264.14 0.31189 281.409 0.31189C332.635 0.31189 342.614 36.8177 342.614 74.5547V204H276.891V90.2737C276.891 72.0351 277.695 54.1973 258.818 54.1973C239.081 54.1973 235.81 70.3172 235.81 88.9852V204H170.06V90.2737C170.06 72.0351 170.891 54.1973 151.986 54.1973C132.278 54.1973 128.979 70.3172 128.979 88.9852V204H64.0876V84.3182C47.6499 84.7477 29.9926 74.9842 30.8242 49.5303C12.3352 48.2705 0 35.0998 0 10.5049V4.54943H122.825Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="home-hero__number">
          <svg
            width="142"
            height="76"
            viewBox="0 0 142 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.6616 5.02679V34.1842L9.57163 2.71221L9.11463 2.26401H8.47453H3.13525H1.56842V3.83084V70.8041V72.371H3.13525H19.8326H21.3995V70.8041V41.1867L53.4813 73.1107L53.9398 73.5669H54.5865H59.9258H61.4926V72.0001V5.02679V3.45996H59.9258H43.2284H41.6616V5.02679ZM68.0667 63.8277V65.3946H69.6336H101.475H103.042V63.8277V53.4628V51.896H101.475H69.6336H68.0667V53.4628V63.8277ZM132.743 3.45996H132.485L132.241 3.54264L106.03 12.4126L104.645 12.8812L105.017 14.2949L108.997 29.4436L109.424 31.0691L111.016 30.5293L119.818 27.5439V70.8041V72.371H121.385H138.083H139.649V70.8041V5.02679V3.45996H138.083H132.743ZM66.9989 30.6401C66.9989 40.9183 75.4148 48.8506 85.5543 48.8506C95.6939 48.8506 104.11 40.9183 104.11 30.6401C104.11 20.244 95.6749 12.5293 85.5543 12.5293C75.4337 12.5293 66.9989 20.244 66.9989 30.6401ZM82.5585 30.6401C82.5585 29.3837 82.9109 28.5514 83.3717 28.0493C83.818 27.5629 84.5131 27.2238 85.5543 27.2238C86.5956 27.2238 87.2906 27.5629 87.7369 28.0493C88.1977 28.5514 88.5501 29.3837 88.5501 30.6401C88.5501 31.8965 88.1977 32.7287 87.7369 33.2309C87.2906 33.7172 86.5956 34.0564 85.5543 34.0564C84.5131 34.0564 83.818 33.7172 83.3717 33.2309C82.9109 32.7287 82.5585 31.8965 82.5585 30.6401Z"
              stroke="white"
              stroke-width="3.13366"
            />
          </svg>
        </div>
        <div class="home-hero__text" v-html="$t('title')"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>
